import { render, staticRenderFns } from "./wave.vue?vue&type=template&id=d5cb6046&scoped=true&"
var script = {}
import style0 from "./wave.vue?vue&type=style&index=0&id=d5cb6046&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d5cb6046",
  null
  
)

export default component.exports