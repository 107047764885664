<template>
  <div class="page">
    <a style="width: auto; padding: 0 10px" v-if="!showTotal"> {{ total }} 条</a>
    <a style="cursor: pointer" class="prev" @click="prevPage">上一页</a>
    <a style="cursor: pointer" v-for="(item, ind) in showPage" :key="ind" @click="tempPageChange(item)"
      :class="item == curPage ? 'active' : ''">{{ item }}</a>
    <a style="cursor: pointer" class="next" @click="nextPage" v-show="Math.ceil(total / pageSize) > curPage">下一页</a>
  </div>
</template>
<script>
export default {
  name: "MyPage",
  //每页大小、总数
  props: ["pageSize", "total", "currPage", "showTotal"],
  data() {
    return {
      showPage: [],
      curPage: 1,
      end: 1,
    };
  },
  watch: {
    //监听总数的变化
    total(newVal) {
      this.allCount = newVal;
      this.showPage = [];
      if (Math.ceil(newVal / this.pageSize) < 10) {
        for (let i = 0; i < Math.ceil(newVal / this.pageSize); i++) {
          this.showPage.push(i + 1);
        }
      } else {
        this.showPage = [1, 2, 3, 4, 5];
      }
    },
    currPage(newVal) {
      this.curPage = newVal;
    },
  },
  created() {
    this.showPage = [];
    if (Math.ceil(this.total / this.pageSize) < 10) {
      for (let i = 0; i < Math.ceil(this.total / this.pageSize); i++) {
        this.showPage.push(i + 1);
      }
    } else {
      this.showPage = [1, 2, 3, 4, 5];
      this.end = this.showPage.length;
      this.pages();
    }
  },
  methods: {
    //上一页
    prevPage() {
      if (this.curPage > 1) {
        this.curPage = this.curPage - 1;
        this.$emit("pageChange", this.curPage);
        if (this.curPage < this.showPage[0]) {
          this.showPage = this.showPage.slice(0, -1); //删除数组最后一个元素
          this.showPage.unshift(this.curPage); //在数组最前面插入当前页
        }
      } else {
        this.$Message.error({ content: "已经是第一页了！" });
      }
    },
    //下一页
    nextPage() {
      if (this.curPage + 1 > this.showPage[this.showPage.length - 1]) {
        if (Math.ceil(this.total / this.pageSize) > this.curPage) {
          this.curPage += 1;
          this.$emit("pageChange", this.curPage);
          this.showPage = this.showPage.slice(1); //删除数组第一个元素
          this.showPage.push(this.curPage); //在数组末尾插入当前页
        } else {
          this.$Message.error({ content: "已经是最后一页了！" });
        }
      } else {
        this.curPage += 1;
        this.$emit("pageChange", this.curPage);
      }
    },
    //切换页码
    tempPageChange(value) {
      if (value == "...") {
        return;
      } else {
        this.curPage = value;
        this.pages();
        this.$emit("pageChange", value);
      }
    },
    pages() {
      if (this.showPage > 7) {
        if (this.curPage <= 5) {
          //当前页码小于等于5
          this.showPage = [1, 2, 3, 4, 5, 6, "...", this.end]; //[1,2,3,4,5,6,'...',最后一页]
        } else if (this.curPage > this.end - 5) {
          //当前页码大于等于最后一页-5
          this.showPage = [
            1,
            "...",
            this.end - 5,
            this.end - 4,
            this.end - 3,
            this.end - 2,
            this.end - 1,
            this.end,
          ]; //[1,'...',尾页-5,尾页-4,尾页-3,尾页-2,尾页-1,尾页]
        } else {
          //当前页码大于5并且当前页码小于最后一页页码-5
          this.showPage = [
            1,
            "...",
            this.curPage - 3,
            this.curPage - 2,
            this.curPage - 1,
            this.curPage,
            this.curPage + 1,
            this.curPage + 2,
            this.curPage + 3,
            "...",
            this.end,
          ]; //[1,'...',当前页-3,当前页-2,当前页-1,当前页,当前页+1,当前页+2,当前页+3,'...',尾页]
        }
      }
    },
  },
};
</script>
<style scoped>
.page {
  width: 735px;
  height: 24px;
  font-size: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.page>a {
  transition: 0.3s;
  display: inline-block;
  font: 14px/30px "微软雅黑";
  width: 30px;
  height: 30px;
  color: #000;
  border: 1px solid #e1e2e3;
  /* color: #fff; */
  text-align: center;
  text-decoration: none;
}

.page>.prev,
.page>.next {
  width: auto;
  font-size: 14px;
  line-height: 30px;
  padding: 0 10px;
}

.page>a:not(:first-child) {
  margin-left: 9px;
}

.page>a:hover,
.page>a.active {
  background: #013060;
  border: 1px solid #013060;
  color: #fff;
}

/*屏幕宽度小于1024px,改变布局和样式*/
@media screen and (max-width: 1024px) {
  .page {
    width: 100%;
    height: 1.2rem;
  }

  .page>a {
    font: 1.0rem/1.7rem "微软雅黑";
    width: 1.7rem;
    height: 1.7rem;
  }

  .page>.prev,
  .page>.next {
    width: auto;
    font-size: 1.0rem;
    line-height: 1.7rem;
    padding: 0 .2rem;
  }

  .page>a:not(:first-child) {
    margin-left: .45rem;
  }
}
</style>

