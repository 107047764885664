<template>
  <div class="floatServiceBox" @click="goRouter()">
    <div class="icon"></div>
    <p>在线客服</p>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    goRouter() {
      window.open(
        "https://work.weixin.qq.com/kfid/kfc3aca39b1203fe8f3",
        "_blank"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.floatServiceBox {
  z-index: 200;
  transition: 0.3s;
  cursor: pointer;
  position: fixed;
  background: url(~@/assets/images/serviceBg.png) center center no-repeat;
  background-size: 100% 100%;
  width: 9.3rem;
  height: 10.3rem;
  right: 5.5rem;
  bottom: 17rem;
  padding: 2.8rem 0 3.6rem;

  .icon {
    transition: 0.3s;
    width: 2rem;
    height: 2rem;
    background: url(~@/assets/images/icon_service02.png) center center no-repeat;
    background-size: 100% 100%;
    margin: 0 0 1rem 3.4rem;
  }

  p {
    white-space: nowrap;
    transition: 0.3s;
    margin: 0;
    font-size: 1.1rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #646464;
    line-height: 1.3rem;
    // padding-left: 2.2rem;
    width: 8.5rem;
    text-align: center;
  }
}

@media screen and (min-width: 1920px) {
  .floatServiceBox {
    width: 93px;
    height: 103px;
    right: 55px;
    bottom: 170px;
    padding: 28px 0 36px;

    .icon {
      width: 20px;
      height: 20px;
      margin: 0 0 10px 34px;
    }

    p {
      font-size: 11px;
      line-height: 13px;
      // padding-left: 22px;
      width: 85px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .floatServiceBox {
    width: 5.58rem;
    height: 6.18rem;
    right: 1.0rem;
    bottom: 17.0rem;
    padding: 1.0rem 0 1.0rem;

    .icon {
      width: 2.0rem;
      height: 2.0rem;
      margin: 0 0 .6rem 1.6rem;
    }

    p {
      font-size: 1.0rem;
      line-height: 1.3rem;
      padding-left: .5rem;
      width: 4.2rem;
    }
  }
}

.floatServiceBox:hover {
  .icon {
    background-image: url(~@/assets/images/icon_service01.png);
  }

  p {
    color: #013060;
  }
}
</style>