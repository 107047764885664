import Vue from 'vue'
// 引入组件
// import myCalendar from './myCalendar/myCalendar';
// Vue.component('myCalendar',myCalendar);

// 循环引入组件
const components = require.context('./', true, /\.vue$/);
components.keys().forEach(filename => {
    // console.log(filename);
    // console.log(components(filename));
    // console.log(components.resolve(filename));
    let arr = filename.replace(/\.\//, '').replace(/\.vue$/, '').split('/');
    // console.log("123654",arr)
    let componentName = arr[1];
    let componentConfig = components(filename).default;
    // console.log(componentName,componentConfig);
    Vue.component(componentName, componentConfig);
})