var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"myHeaderBox"},[_c('div',{staticClass:"myNav"},[_c('div',{staticClass:"myLogo",on:{"click":() => {
      _vm.$router.push('/');
    }}},[_c('img',{attrs:{"src":require("@/assets/images/logo02.png"),"alt":""}})]),_c('div',{staticClass:"mN_right"},[_vm._m(0),_c('div',{staticClass:"mN_icons"},[_c('div',{staticClass:"mN_icon icon_search02",on:{"click":function($event){_vm.phSearchShow = !_vm.phSearchShow}}}),_c('div',{staticClass:"mN_icon icon_menu02",on:{"click":function($event){_vm.phNavShow = !_vm.phNavShow}}})])])]),_c('div',{staticStyle:{"height":"6.4rem"}}),(_vm.show)?_c('div',{staticClass:"mh_img",style:('background: url(' + _vm.imgBg + ') no-repeat center center')},[_c('div',{staticClass:"mh_title"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.title))])])]):_vm._e(),_c('van-popup',{staticStyle:{"z-index":"2000"},style:({ height: '100%', width: '100%' }),attrs:{"position":"right"},model:{value:(_vm.phNavShow),callback:function ($$v) {_vm.phNavShow=$$v},expression:"phNavShow"}},[_c('div',{staticClass:"phNav"},[_c('div',{staticClass:"pn_top"},[_c('div',{staticClass:"pnh_tr"},[_c('div',{staticClass:"pnh_search01"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchText),expression:"searchText"}],attrs:{"type":"text","placeholder":"搜索","name":"","id":""},domProps:{"value":(_vm.searchText)},on:{"input":function($event){if($event.target.composing)return;_vm.searchText=$event.target.value}}}),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"pnh_rightArrow",on:{"click":function($event){return _vm.search(2)}}})]),_c('div',{staticClass:"pnh_tl"},[_c('div',{staticClass:"ptl_text"},[_c('div',{staticClass:"mN_lag pointer"},[_c('div',{staticClass:"text"},[_vm._v("CN")])])]),_c('div',{staticClass:"pnh_close",on:{"click":() => {
            _vm.phNavShow = false;
          }}})])]),_c('div',{staticClass:"pn_area"},[_c('phoneMenu')],1),_c('div',{staticClass:"pn_footer"},[_c('a',{staticStyle:{"margin-right":".6rem"},attrs:{"href":"http://jingzhe.ims-pro.cn/member/loginjz.aspx?ul="}},[_vm._v("内部系统")]),_vm._v("丨 "),_c('a',{staticStyle:{"margin-left":".4rem"},attrs:{"href":"https://exmail.qq.com/login"}},[_vm._v("企业邮箱")])])])]),_c('van-popup',{staticStyle:{"z-index":"2000"},style:({ height: '60%', width: '100%' }),attrs:{"position":"top"},model:{value:(_vm.phSearchShow),callback:function ($$v) {_vm.phSearchShow=$$v},expression:"phSearchShow"}},[_c('div',{staticClass:"ph_search"},[_c('div',{staticClass:"ps_top"},[_c('div'),_c('div',{staticClass:"ps_logo"}),_c('div',{staticClass:"ps_close"},[_c('div',{staticClass:"icon_close",on:{"click":() => {
            _vm.phSearchShow = false;
          }}})])]),_c('div',{staticClass:"ps_mid"},[_c('div',{staticClass:"ps_title"},[_vm._v("探索京哲")]),_c('div',{staticClass:"ps_inputArea"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchText),expression:"searchText"}],attrs:{"type":"text","placeholder":"搜索"},domProps:{"value":(_vm.searchText)},on:{"input":function($event){if($event.target.composing)return;_vm.searchText=$event.target.value}}}),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"ps_rightArrow",on:{"click":function($event){return _vm.search(2)}}},[_c('div',{staticClass:"ps_icon"})])]),_c('div',{staticClass:"ps_label"},[_vm._v("京哲智慧，开启未来")])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mN_lag"},[_c('div',{staticClass:"mN_lag pointer"},[_c('div',{staticClass:"text"},[_vm._v("CN")])])])
}]

export { render, staticRenderFns }