<template>
  <div>
    <transition name="fade">
      <div @touchend="end" @touchstart="start" @touchmove="move" class="swiper">
        <div @click="chooseItem(item, index)" v-for="(item, index) in imgs" :style="config5[index]" :key="item.cover">
          <img :src="item.cover" style="height: 100%"
            @click="$router.push(isPhone ? ('/mobileJingzheDynamicDetail?id=' + item.id) : ('/jingzheDynamicDetail?id=' + item.id))" />
        </div>
      </div>
    </transition>
    <div class="btnArea">
      <div class="btns">
        <div class="btn" @click="prev"><i class="el-icon-arrow-left"></i></div>
        <div class="btn" @click="next"><i class="el-icon-arrow-right"></i></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "zt",
  data() {
    return {
      loading: true,
      currentIndex: 3, //当前中间imgs数组中index
      centerInfo: "", // 当前中间信息
      startX: "",
      endX: "",
      imgs: [],
      previous: 0,
      config5: [
        {
          id: "center",
          position: "absolute",
          //   width: "45%",
          height: "100%",
          top: "0px",
          left: "22.5%",
          marginLeft: "-22.5%",
          opacity: 1,
          zIndex: 4,
          transition: ".4s",
        },
        {
          id: "D",
          position: "absolute",
          //   width: "28%",
          height: "82%",
          top: "9%",
          left: "30%",
          opacity: 1,
          zIndex: 3,
          transition: ".4s",
        },
        {
          id: "E",
          position: "absolute",
          //   width: "22%",
          height: "72%",
          top: "14%",
          left: "50%",
          opacity: 1,
          zIndex: 2,
          transition: ".4s",
        },
        {
          id: "E+",
          position: "absolute",
          //   width: "22%",
          height: "62%",
          top: "19%",
          left: "68.5%",
          opacity: 1,
          zIndex: 1,
          transition: ".4s",
        },
        {
          id: "-A",
          position: "absolute",
          width: "22%",
          height: "72%",
          top: "19.2%",
          left: "-22%",
          opacity: 0,
          zIndex: 0,
          transition: ".4s",
        },
        {
          id: "A",
          position: "absolute",
          width: "22%",
          height: "72%",
          top: "19.2%",
          left: "0%",
          opacity: 1,
          zIndex: 1,
          transition: ".4s",
        },
        {
          id: "B",
          position: "absolute",
          width: "28%",
          height: "82%",
          top: "14%",
          left: "13%",
          opacity: 1,
          zIndex: 2,
          transition: ".4s",
        },
      ],

      isPhone: false
    };
  },
  methods: {
    // 获取数据
    async getData() {
      this.$nextTick(() => {
        this.loading = false;
      });
    },
    // 滑动上一个
    prev(index) {
      // this.imgs.unshift(this.imgs.pop());
      this.config5.push(this.config5.shift());
      this.currentIndex = this.currentIndex - 1;
      if (this.currentIndex < 0) {
        this.currentIndex = this.imgs.length - 1;
      }
      this.centerCard();
      this.centerIndex("prev");
    },
    // 滑动下一个
    next() {
      // this.imgs.push(this.imgs.shift());
      this.config5.unshift(this.config5.pop());
      this.currentIndex = this.currentIndex + 1;
      if (this.currentIndex > this.imgs.length - 1) {
        this.currentIndex = 0;
      }
      this.centerCard();
      this.centerIndex("next");
      // console.log(this.currentIndex);
    },
    // 开始移动端滑动屏幕
    start(event) {
      this.startX = event.changedTouches[0].clientX;
      this.startY = event.changedTouches[0].clientY;
    },
    // 连续滑动
    move(event) {
      this.endY = event.changedTouches[0].clientY;
      this.endX = event.changedTouches[0].clientX;
      this.stopDefault(event);
      // 如果是滑动，注解（223行到231行）这段。如果是连续滑动，放开（223行到231行）注解
      this.interval = this.endX - this.startX;
      if (this.interval > 40) {
        this.startX = this.endX;
        this.prev();
      }
      if (this.interval < -40) {
        this.startX = this.endX;
        this.next();
      }
    },
    // 滑动
    end(event) {
      // 如果是滑动，放开（236行到238行）的注解。如果是连续滑动，注解（236行到238行）
      // this.endY = event.changedTouches[0].clientY;
      // this.endX = event.changedTouches[0].clientX;
      // this.formatSwiper();
    },
    formatSwiper() {
      if (this.startX > this.endX) {
        console.log("左边滑动");
        if (this.startX > this.endX + 40) {
          this.next();
        }
      } else {
        console.log("右边滑动");
        if (this.endX > this.startX + 40) {
          this.prev();
        }
      }
    },
    // 阻止touchmove的横向默认事件（ios快捷操作会关闭页面）
    stopDefault(event) {
      let differenceY = this.endY - this.startY;
      let differenceX = this.endX - this.startX;
      if (Math.abs(differenceX) > Math.abs(differenceY)) {
        event.preventDefault();
      }
    },
    // 当前imgs在位置上的index（并非img数组的index）
    centerIndex(val) {
      if (val == "prev") {
        for (let val of this.imgs) {
          if (val.index == this.imgs.length - 1) {
            val.index = 0;
          } else {
            val.index = val.index + 1;
          }
        }
      } else {
        for (let val of this.imgs) {
          if (val.index == 0) {
            val.index = this.imgs.length - 1;
          } else {
            val.index = val.index - 1;
          }
        }
      }
    },
    // 点击
    chooseItem(item, index) {
      let cycles = item.index;
      if (item.index < 3) {
        for (let i = 0; i < 3 - cycles; i++) {
          console.log(item.index);
          this.prev();
        }
      } else if (item.index > 3) {
        for (let i = -1; i < item.index - 3; i++) {
          this.next();
        }
      } else if (item.index == 3) {
        console.log("投票");
      }
    },
    // 计算中间卡片信息
    centerCard() {
      this.centerInfo = this.imgs[this.currentIndex];
      this.$emit("centerInfo", this.centerInfo);
      // this.$emit('centerInfo', this.centerInfo);
      // console.log(this.imgs[2].id);
    },

    addCardStyle() {
      if (this.imgs.length > 7) {
        let addtime = this.imgs.length - 7;
        for (let i = 0; i < addtime; i++) {
          console.log("add");
          this.config5.push({
            id: "center",
            position: "absolute",
            width: "45%",
            height: "100%",
            top: "0px",
            left: "50%",
            marginLeft: "-22.5%",
            opacity: 0,
            transition: ".1s",
          });
        }
      }
    },
  },
  created() {
    if (document.body.clientWidth < 1024) {
      this.isPhone = true
    }
    this.getData();
    this.centerCard(); // 获取中间卡片信息
    this.addCardStyle(); // 加入样式位置的index
  },
};
</script>

<style lang="scss" scoped>
.swiper {
  width: 100%;
  //   border: red 1px solid;
  height: 38rem;
  position: relative;
  overflow: hidden;

  div {
    opacity: 0;
  }
}

.btnArea {
  // display: flex;
  // justify-content: right;
  margin-bottom: 3.6rem;
  height: 3.6rem;

  .btns {
    float: right;
    display: flex;

    // column-gap: 1.8rem;
    ::v-deep .btn {
      margin-left: 1.8rem;
      transition: 0.3s;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.6rem;
      height: 3.6rem;
      border: 0.1rem solid #364c98;
      border-radius: 50%;
      font-size: 1.8rem;
      color: #364c98;

      i {
        font-weight: 600;
      }
    }

    .btn:hover {
      border-color: #fcbe43;
      color: #fcbe43;
    }
  }
}

.btnArea::after {
  clear: both;
  content: "";
  display: block;
}

/*屏幕宽度大于1920px,改变布局和样式*/
@media screen and (min-width: 1920px) {
  .swiper {
    height: 360px;
  }

  .btnArea {
    // padding-bottom: 36px;
    margin-bottom: 36px;
    height: 36px;

    .btns {

      // column-gap:18px;
      ::v-deep .btn {
        margin-left: 18px;
        width: 36px;
        height: 36px;
        border: 1px solid #364c98;
        font-size: 18px;

        i {
          font-weight: 600;
        }
      }

      .btn:hover {
        border-color: #fcbe43;
        color: #fcbe43;
      }
    }
  }
}

/*屏幕宽度小于1024px,改变布局和样式*/
@media screen and (max-width: 1024px) {
  .swiper {
    width: 100%;
    //   border: red 1px solid;
    height: 10.8rem;
    position: relative;
    overflow: hidden;

    div {
      opacity: 0;
    }
  }

  .btnArea {
    // display: flex;
    // justify-content: right;
    margin: 1.2rem 0;
    height: 2.4rem;

    .btns {
      float: right;
      display: flex;

      // column-gap: .18rem;
      ::v-deep .btn {
        margin-left: .8rem;
        transition: 0.3s;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.4rem;
        height: 2.4rem;
        border: .1rem solid #364c98;
        border-radius: 50%;
        font-size: 1.2rem;
        color: #364c98;

        i {
          font-weight: 600;
        }
      }

      .btn:hover {
        border-color: #fcbe43;
        color: #fcbe43;
      }
    }
  }

  .btnArea::after {
    clear: both;
    content: "";
    display: block;
  }
}
</style>

