import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

// **********
import './permission'


// 导入axios
import axios from 'axios'
Vue.prototype.$axios = axios

// import Vconsole from 'vconsole';
// let vConsole = new Vconsole();
// export default vConsole

import '@/views/myComponents/myComponents' // 组件js

// px转化rem
import "./utils/px2rem"
// import 'lib-flexible'

// 引入动画
import '@/assets/style/keyframes.css'

//引入字体
import '@/assets/css/stylesheet.css'


import tools from "@/utils/tools"; //工具函数
Vue.prototype.$tools = tools

// import VConsole from 'vconsole';
// const vConsole = new VConsole();
// export default vConsole

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);


// 监听移动端手势
import VueTouch from 'vue-touch'
Vue.use(VueTouch, { name: 'v-touch' })

// 视频播放器
import VideoPlayer from 'vue-video-player/src';
import 'vue-video-player/src/custom-theme.css';
import 'video.js/dist/video-js.css'
Vue.use(VideoPlayer)

import VueAnimateOnScroll from 'vue-animate-onscroll'
Vue.use(VueAnimateOnScroll)

// 全局变量
import { imgUrl } from '@/utils/config.js';
// 全局方法挂载
Vue.prototype.imgUrl = imgUrl


window.pcShow = 1

new Vue({
  axios,
  router,
  data() {
    return {
      pcShow: true
    }
  },
  store,
  render: h => h(App)
}).$mount('#app')
