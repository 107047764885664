<template>
  <div class="phoneFooterBox" id="phoneFooterBox">
    <div class="m_content">
      <div class="m_logo">
        <img src="@/assets/images/logo02.png" alt="" />
      </div>
      <div class="dotted_line">
        <img src="@/assets/images/dottedLine.png" alt="" />
      </div>
      <div class="m_menuBox">
        <div class="menuItem" v-for="item in menuList" :key="item.id">
          <div class="menuLabel" @click="$router.push(item.path)">
            {{ item.label }}
            <div class="line"></div>
          </div>
          <div class="menuDataBox">
            <div class="menuDataLabel" v-for="ele in item.dataList" :key="ele.id" @click="$router.push(ele.path)">
              <div>
                {{ ele.title }}
                <div class="line_2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dotted_line">
        <img src="@/assets/images/dottedLine.png" alt="" />
      </div>
      <div class="m_menuBottom">
        <div class="m_bottomItem">
          <div class="m_bItop">
            <div class="ml">
              <p>WECHAT</p>
              <span>微信公众号：</span>
            </div>
            <el-popover placement="top" trigger="hover">
              <img :src="$tools.localStorageFn.get('PAGE_DATE').wechat" alt="" style="width: 128px; height: 128px" />
              <div slot="reference" class="mr" style="cursor: pointer">
                <img src="@/assets/images/icon_WECHAT.png" alt="" style="width: 1.55rem; height: 1.3rem" />
              </div>
            </el-popover>
          </div>
          <div class="m_bIbottom">扫一扫了解更多信息</div>
        </div>
        <div class="m_bottomItem">
          <div class="m_bItop">
            <div class="ml">
              <p>PHONE</p>
              <span>前台总机：</span>
            </div>
            <div class="mr">
              <img src="@/assets/images/icon_PHONE.png" alt="" style="width: 1.4rem; height: 1.15rem" />
            </div>
          </div>
          <div class="m_bIbottom">
            {{ $tools.localStorageFn.get("PAGE_DATE").switchboard }}
          </div>
        </div>
        <div class="m_bottomItem">
          <div class="m_bItop">
            <div class="ml">
              <p>ADDRESS</p>
              <span>公司地址：</span>
            </div>
            <div class="mr">
              <img src="@/assets/images/icon_ADDRESS.png" alt="" style="width: 1.2rem; height: 1.35rem" />
            </div>
          </div>
          <div class="m_bIbottom">
            {{ $tools.localStorageFn.get("PAGE_DATE").address }}
          </div>
        </div>
        <div class="m_bottomItem">
          <div class="m_bItop">
            <div class="ml">
              <p>EMAIL</p>
              <span>办公邮箱：</span>
            </div>
            <div class="mr">
              <img src="@/assets/images/icon_EMAIL.png" alt="" style="width: 1.4rem; height: 1.4rem" />
            </div>
          </div>
          <div class="m_bIbottom">
            {{ $tools.localStorageFn.get("PAGE_DATE").email }}
          </div>
        </div>
      </div>
      <!-- <div class="m_top" @click="() => {
        $emit('move', 1);
      }
        ">
        <img src="@/assets/images/icon_backTop.png" alt="" />
      </div> -->
    </div>
    <div class="m_declaration">
      {{ $tools.localStorageFn.get("PAGE_DATE").ed }}
      <span
        @click="$router.push('/mobileLegalDeclaration')">免责声明</span><!-- 丨<span style="padding: 0" @click="goRouter">内部系统</span> -->
      <!-- <div class="declarationBox" @mouseover="diaShow = true"> -->
      <!-- <div class="circleTip">声明</div> -->
      <!-- <div class="dialogueBox">
          <img src="@/assets/images/logoSmall02.png" alt="" />
          提示公众注意声明
        </div> -->
      <!-- </div> -->
    </div>

    <div class="dialog" :class="diaShow ? 'show' : ''" @mouseleave="diaShow = false">
      <div class="dia_header">
        <img src="@/assets/images/logoSmall02.png" alt="" />
        {{ $tools.localStorageFn.get("PAGE_DATE").prompt_title }}
      </div>
      <div class="dia_content">
        {{ $tools.localStorageFn.get("PAGE_DATE").prompt_content }}
        <div class="dia_btn" @click="$router.push('/legalDeclaration')">
          查看详情
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 弹框展示
      diaShow: false,
      menuList: [
        {
          id: "0002",
          label: "京哲介绍",
          dataList: [
            { id: "00001", title: "走进京哲", path: "/mobileEnterJingzhe" },
            { id: "00002", title: "京哲荣誉", path: "/mobileJingzheHonor" },
            { id: "00003", title: "社会责任", path: "/mobileJingzheWelfare" },
          ],
          path: "/mobileEnterJingzhe",
        },
        {
          id: "0003",
          label: "京哲动态",
          dataList: [
            { id: "00001", title: "京哲新闻", path: "/mobileJingzheDynamic" },
            // { id: "00002", title: "京哲公益", path: "/jingzheWelfare" },
            { id: "00003", title: "京哲业绩", path: "/mobileJingzhePerformance" },
            { id: "00004", title: "典型案例", path: "/mobileTypicalCase" },
          ],
          path: "/mobileJingzheDynamic",
        },
        {
          id: "0004",
          label: "专业领域",
          dataList: [],
          path: "/mobileAreaExpertise",
        },
        {
          id: "0005",
          label: "专业人员",
          dataList: [
            { id: "00001", title: "专业人员", path: "/mobileProfessionalPersonnel" },
          ],
          path: "/mobileProfessionalPersonnel",
        },
        {
          id: "0006",
          label: "合作对象",
          dataList: [
            { id: "00001", title: "合作对象", path: "/mobileCooperativePartner" },
          ],
          path: "/mobileCooperativePartner",
        },
        {
          id: "0007",
          label: "党建工作",
          dataList: [
            { id: "00001", title: "党建工作", path: "/mobilePartyBuildingWork" },
          ],
          path: "/mobilePartyBuildingWork",
        },
        {
          id: "0008",
          label: "联系我们",
          dataList: [
            { id: "00001", title: "在线留言", path: "/mobileOnlineMessage" },
            { id: "00002", title: "联系我们", path: "/mobileContactUs" },
            { id: "00003", title: "工作机会", path: "/mobileJobOpportunity" },
          ],
          path: "/mobileOnlineMessage",
        },
      ],
    };
  },
  created() {
    this.initData();
  },
  methods: {
    // 初始化数据
    initData() {
      let fieldList = this.$tools.localStorageFn.get("FIELD_LIST");
      let list = this.$tools.localStorageFn.get("CATEGORIES_LIST")
      if (list) {
        list.forEach((item) => {
          if (item.id == 51) {
            this.menuList[2].label = item.title
          } else if (item.id == 44) {
            this.menuList[0].label = item.title
            this.menuList[0].dataList[0].title = item.children[0].title
            this.menuList[0].dataList[1].title = item.children[1].title
            this.menuList[0].dataList[2].title = item.children[2].title
          } else if (item.id == 47) {
            this.menuList[1].label = item.title
            this.menuList[1].dataList[0].title = item.children[0].title
            this.menuList[1].dataList[1].title = item.children[1].title
            this.menuList[1].dataList[2].title = item.children[2].title
          } else if (item.id == 52) {
            this.menuList[3].label = item.title
            this.menuList[3].dataList[0].title = item.title
          } else if (item.id == 53) {
            this.menuList[4].label = item.title
            this.menuList[4].dataList[0].title = item.children[0].title
          } else if (item.id == 55) {
            this.menuList[5].label = item.title
            this.menuList[5].dataList[0].title = item.children[0].title
          } else if (item.id == 57) {
            this.menuList[6].label = item.title
            this.menuList[6].dataList[0].title = item.children[2].title
            this.menuList[6].dataList[1].title = item.children[0].title
            this.menuList[6].dataList[2].title = item.children[1].title
          }
        })
      } else {
        this.$axios
          .get(process.env.VUE_APP_API + "/api/v1/categories")
          .then((res) => {
            console.log("所有栏目", res);
            if (res.status === 200) {
              this.$tools.localStorageFn.set("CATEGORIES_LIST", res.data);
              res.data.forEach((item) => {
                if (item.id == 51) {
                  this.menuList[2].label = item.title
                } else if (item.id == 44) {
                  this.menuList[0].label = item.title
                  this.menuList[0].dataList[0].title = item.children[0].title
                  this.menuList[0].dataList[1].title = item.children[1].title
                  this.menuList[0].dataList[2].title = item.children[2].title
                } else if (item.id == 47) {
                  this.menuList[1].label = item.title
                  this.menuList[1].dataList[0].title = item.children[0].title
                  this.menuList[1].dataList[1].title = item.children[1].title
                  this.menuList[1].dataList[2].title = item.children[2].title
                } else if (item.id == 52) {
                  this.menuList[3].label = item.title
                  this.menuList[3].dataList[0].title = item.title
                } else if (item.id == 53) {
                  this.menuList[4].label = item.title
                  this.menuList[4].dataList[0].title = item.children[0].title
                } else if (item.id == 55) {
                  this.menuList[5].label = item.title
                  this.menuList[5].dataList[0].title = item.children[0].title
                } else if (item.id == 57) {
                  this.menuList[6].label = item.title
                  this.menuList[6].dataList[0].title = item.children[2].title
                  this.menuList[6].dataList[1].title = item.children[0].title
                  this.menuList[6].dataList[2].title = item.children[1].title
                }
              })
            } else {
              this.$message.error(res.statusText);
            }
          });
      }


      this.menuList[2].dataList = [
        {
          id: fieldList[0].id,
          title: fieldList[0].name,
          path: "/mobileAreaExpertiseDetail?id=" + fieldList[0].id,
        },
        {
          id: fieldList[1].id,
          title: fieldList[1].name,
          path: "/mobileAreaExpertiseDetail?id=" + fieldList[1].id,
        },
        {
          id: "00003",
          title: "查看全.部",
          path: "/mobileAreaExpertise",
        },
      ];
    },

    goRouter() {
      window.location.href = 'http://jingzhe.ims-pro.cn/member/loginjz.aspx?ul=';
    }
  },
};
</script>

<style lang="scss" scoped>
.phoneFooterBox {
  width: 100%;
  background: #0c3d6d;
  position: relative;

  .m_content {
    width: 35.0rem;
    padding: 1.6rem 0 1.6rem;
    margin: 0 auto;

    .m_logo {
      width: 12.8rem;
      height: 2.8rem;
      margin: 0 auto;
      margin-bottom: 1.6rem;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .dotted_line {
      width: 100%;

      img {
        width: 100%;
        height: .02rem;
      }
    }

    .m_menuBox {
      padding: .45rem 0 .5rem;
      display: flex;

      .menuItem {
        width: calc(100% / 7);

        .menuLabel {
          cursor: pointer;
          font-size: 1.0rem;
          font-family: SimHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 1.8rem;

          .line {
            margin-top: .3rem;
            width: 0;
            height: .1rem;
            background: #ffffff;
            transition: 0.3s;
          }
        }

        .menuLabel:hover {
          .line {
            width: .8rem;
          }
        }

        .menuDataBox {
          margin-top: .0rem;

          .menuDataLabel {
            cursor: pointer;
            font-size: .7rem;
            font-family: Microsoft YaHei UI;
            font-weight: 400;
            color: #ececfb;
            line-height: 1.8rem;
            margin-bottom: .0rem;
            width: 5.0rem;

            >div {
              width: 100%;
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .line_2 {
              margin-top: .3rem;
              width: 0;
              height: .1rem;
              background: #ffffff;
              transition: 0.3s;
            }
          }

          .menuDataLabel:hover {
            .line_2 {
              width: 100%;
            }
          }

          .menuDataLabel:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .m_menuBottom {
      margin-top: 1.6rem;
      display: flex;
      justify-content: space-between;

      .m_bottomItem {
        width: 25%;

        .m_bItop {
          display: flex;

          .ml {
            font-family: Microsoft YaHei UI;
            font-weight: 400;
            color: #ebebfa;
            line-height: 1.4rem;

            p {
              margin: 0;
              font-size: .9rem;
            }

            span {
              font-size: .7rem;
            }
          }

          .mr {
            width: 2.4rem;
            height: 2.4rem;
            background: rgba(255, 255, 255, 0);
            border: .1rem solid #ececfb;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: .8rem;
          }
        }

        .m_bIbottom {
          width: 8.0rem;
          margin-top: .6rem;
          font-size: .7rem;
          font-family: SimHei;
          font-weight: 400;
          color: #ebebf9;
          line-height: 1.1rem;
        }
      }
    }
  }

  .m_declaration {
    background: #104e8b;
    text-align: center;
    font-size: .7rem;
    font-weight: 400;
    color: #ffffff;
    line-height: 3.2rem;
    white-space: pre-wrap;

    span {
      cursor: pointer;
      padding-left: .4rem;
    }

    .declarationBox {
      cursor: pointer;
      position: absolute;
      display: flex;
      align-items: center;
      left: 1.4rem;
      top: 4.1rem;

      .circleTip {
        width: .39rem;
        height: .39rem;
        background: #0c3d6d;
        border: .03rem #fff solid;
        border-radius: 50%;
        font-size: .11rem;
        font-family: SimHei;
        font-weight: 400;
        color: #ffffff;
        line-height: .33rem;
      }

      .dialogueBox {
        position: relative;
        margin-left: .2rem;
        height: .43rem;
        background: #0c3d6d;
        padding: 0 .2rem;
        display: flex;
        align-items: center;
        font-size: .14rem;
        font-family: SimHei;
        font-weight: 400;
        color: #ffffff;

        img {
          width: .26rem;
          height: .14rem;
          margin-right: .13rem;
        }
      }

      .dialogueBox::before {
        content: "";
        position: absolute;
        border-top: .13rem solid transparent;
        border-bottom: .13rem solid transparent;
        border-right: .13rem solid #0c3d6d;
        width: 0;
        height: 0;
        left: -.13rem;
      }
    }
  }

  .m_top {
    cursor: pointer;
    width: .33rem;
    height: .33rem;
    position: absolute;
    right: 60px;
    bottom: 1.1rem;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .dialog {
    width: 2.63rem;
    position: absolute;
    left: 2.2rem;
    bottom: 1.2rem;
    transform: translateX(-6.0rem);
    transition: 0.6s;

    .dia_header {
      height: .45rem;
      background: #104e8b;
      padding: 0 .23rem;
      display: flex;
      align-items: center;
      font-size: .14rem;
      font-family: SimHei;
      font-weight: 400;
      color: #ffffff;

      img {
        width: .26rem;
        height: .14rem;
        margin-right: .13rem;
      }
    }

    .dia_content {
      background: #ffffff;
      padding: .33rem .2rem;
      font-size: .15rem;
      font-family: SimHei;
      font-weight: 400;
      color: #0c3d6c;
      line-height: .24rem;

      .dia_btn {
        cursor: pointer;
        margin: .43rem auto 0;
        width: 1.2rem;
        height: .31rem;
        background: rgba(12, 61, 108, 0);
        border: .01rem solid #0c3d6c;
        border-radius: .16rem .16rem .16rem .16rem;
        font-size: .14rem;
        font-family: SimHei;
        font-weight: 400;
        color: #0c3d6c;
        line-height: .31rem;
        text-align: center;
      }
    }
  }

  .show {
    transform: translateX(0) !important;
  }
}
</style>