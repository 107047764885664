<template>
  <div class="myDeclarationBox" v-if="show">
    <div class="hwtcbox w_width on">
      <div class="content">
        <div class="left">
          <div class="tit">
            关于提示公众注意的声明
            <div class="none" @click="show = false">×</div>
          </div>
          <div class="info">
            <p>
              <span>京哲律师事务</span>
            海问律师事务所近期收到反映，有不法分子冒充本所律师，推销投资产品。该行为涉嫌诈骗，且严重损害了本所声
            </p>
          </div>
          <div class="more">
            <a
              href="http://118.190.151.212/uploads/files/73f429ec316de58484bae14075bae1e0.pdf"
              target="_blank"
              >查看详情</a
            >
            <div class="line"></div>
          </div>
        </div>
        <!-- <div class="right">
            <a
              href="http://www.haiwen-law.com/upload/pdf/202209/%E5%A3%B0%E6%98%8E360CB85B715F4F25B7406AB8AD922A30.pdf"
              target="_blank"
            >
              <img src="@/assets/images/pdf.jpg" />
            </a>
          </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.myDeclarationBox {
  z-index: 200;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #f7f7f7;
  padding-bottom: 16rem;
  .tit {
    position: relative;
    background: #16275f;
    width: 100%;
    line-height: 28rem;
    font-size: 14rem;
    font-family: FZCuHeiSongS-B-GB;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    .none {
      cursor: pointer;
      position: absolute;
      right: 50rem;
      top: 0;
    }
  }
  .info {
    text-align: left;
    p {
      padding: 16rem 16rem;
      margin: 0;
      font-size: 12rem;
      font-family: Microsoft YaHei UI;
      font-weight: 400;
      color: #15265f;
      line-height: 20rem;
      span {
        color: #ff2626;
        font-weight: 500;
      }
    }
  }
  .more {
    position: relative;
    display: flex;
    justify-content: center;
    a {
      width: 72rem;
      height: 32rem;
      background: #f4f4f4;
      border: 0.1rem solid #dddddd;
      border-radius: 16rem;
      font-size: 12rem;
      font-family: Microsoft YaHei UI;
      font-weight: 400;
      color: #16275f;
      line-height: 32rem;
      text-align: center;
      margin: 0 auto;
      z-index: 205;
    }
    .line {
      z-index: 200;
      width: 90%;
      height: 1rem;
      background: #d6d6d6;
      position: absolute;
      top: 50%;
    }
  }
}
</style>