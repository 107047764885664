var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"homePage08Box",attrs:{"id":"homePage08Box"}},[_c('div',{staticClass:"m_content"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"m_menuBox"},_vm._l((_vm.menuList),function(item){return _c('div',{key:item.id,staticClass:"menuItem"},[_c('div',{staticClass:"menuLabel",on:{"click":function($event){return _vm.$tools.blankPush(item.path)}}},[_vm._v(" "+_vm._s(item.label)+" "),_c('div',{staticClass:"line"})]),_c('div',{staticClass:"menuDataBox"},_vm._l((item.dataList),function(ele){return _c('div',{key:ele.id,staticClass:"menuDataLabel",on:{"click":function($event){return _vm.$tools.blankPush(ele.path)}}},[_c('div',[_vm._v(" "+_vm._s(ele.title)+" "),_c('div',{staticClass:"line_2"})])])}),0)])}),0),_vm._m(2),_c('div',{staticClass:"m_menuBottom"},[_c('div',{staticClass:"m_bottomItem"},[_c('div',{staticClass:"m_bItop"},[_vm._m(3),_c('el-popover',{attrs:{"placement":"top","trigger":"hover"}},[_c('img',{staticStyle:{"width":"128px","height":"128px"},attrs:{"src":_vm.$tools.localStorageFn.get('PAGE_DATE').wechat,"alt":""}}),_c('div',{staticClass:"mr",staticStyle:{"cursor":"pointer"},attrs:{"slot":"reference"},slot:"reference"},[_c('img',{staticStyle:{"width":"31px","height":"26px"},attrs:{"src":require("@/assets/images/icon_WECHAT.png"),"alt":""}})])])],1),_c('div',{staticClass:"m_bIbottom"},[_vm._v("扫一扫了解更多信息")])]),_c('div',{staticClass:"m_bottomItem"},[_vm._m(4),_c('div',{staticClass:"m_bIbottom"},[_vm._v(" "+_vm._s(_vm.$tools.localStorageFn.get("PAGE_DATE").switchboard)+" ")])]),_c('div',{staticClass:"m_bottomItem"},[_vm._m(5),_c('div',{staticClass:"m_bIbottom"},[_vm._v(" "+_vm._s(_vm.$tools.localStorageFn.get("PAGE_DATE").address)+" ")])]),_c('div',{staticClass:"m_bottomItem"},[_vm._m(6),_c('div',{staticClass:"m_bIbottom"},[_vm._v(" "+_vm._s(_vm.$tools.localStorageFn.get("PAGE_DATE").email)+" ")])])]),_c('div',{staticClass:"m_top",on:{"click":() => {
      _vm.toTop();
      _vm.$emit('move', 1);
    }}},[_c('img',{attrs:{"src":require("@/assets/images/icon_backTop.png"),"alt":""}})])]),_c('div',{staticClass:"m_declaration"},[_vm._v(" "+_vm._s(_vm.$tools.localStorageFn.get("PAGE_DATE").ed)+" "),_c('span',{on:{"click":function($event){return _vm.$tools.blankPush('/legalDeclaration')}}},[_vm._v("免责声明")]),_c('div',{staticClass:"declarationBox",on:{"mouseover":function($event){_vm.diaShow = true}}},[_c('div',{staticClass:"circleTip"},[_vm._v("声明")])])]),_c('div',{staticClass:"dialog",class:_vm.diaShow ? 'show' : '',on:{"mouseleave":function($event){_vm.diaShow = false}}},[_c('div',{staticClass:"dia_header"},[_c('img',{attrs:{"src":require("@/assets/images/logoSmall02.png"),"alt":""}}),_vm._v(" "+_vm._s(_vm.$tools.localStorageFn.get("PAGE_DATE").prompt_title)+" ")]),_c('div',{staticClass:"dia_content"},[_vm._v(" "+_vm._s(_vm.$tools.localStorageFn.get("PAGE_DATE").prompt_content)+" ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m_logo"},[_c('img',{attrs:{"src":require("@/assets/images/logo02.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dotted_line"},[_c('img',{attrs:{"src":require("@/assets/images/dottedLine.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dotted_line"},[_c('img',{attrs:{"src":require("@/assets/images/dottedLine.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ml"},[_c('p',[_vm._v("WECHAT")]),_c('span',[_vm._v("微信公众号：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m_bItop"},[_c('div',{staticClass:"ml"},[_c('p',[_vm._v("PHONE")]),_c('span',[_vm._v("前台总机：")])]),_c('div',{staticClass:"mr"},[_c('img',{staticStyle:{"width":"28px","height":"23px"},attrs:{"src":require("@/assets/images/icon_PHONE.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m_bItop"},[_c('div',{staticClass:"ml"},[_c('p',[_vm._v("ADDRESS")]),_c('span',[_vm._v("公司地址：")])]),_c('div',{staticClass:"mr"},[_c('img',{staticStyle:{"width":"24px","height":"27px"},attrs:{"src":require("@/assets/images/icon_ADDRESS.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m_bItop"},[_c('div',{staticClass:"ml"},[_c('p',[_vm._v("EMAIL")]),_c('span',[_vm._v("办公邮箱：")])]),_c('div',{staticClass:"mr"},[_c('img',{staticStyle:{"width":"28px","height":"20px"},attrs:{"src":require("@/assets/images/icon_EMAIL.png"),"alt":""}})])])
}]

export { render, staticRenderFns }