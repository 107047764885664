<template>
  <div class="chartNum">
    <div class="box-item">
      <li
        :class="{ 'number-item': !isNaN(item), 'mark-item': isNaN(item) }"
        v-for="(item, index) in orderNum"
        :key="index"
      >
        <span v-if="!isNaN(item)">
          <i ref="numberItem">0123456789</i>
        </span>
        <span class="comma" v-else>{{ item }}</span>
      </li>
    </div>
  </div>
</template>
  <script>
export default {
  props: {
    // 显示的数字
    number: {},
    // 显示的长度
    length: {
      type: Number,
    },
  },
  data() {
    return {
      orderNum: ["0", "0", "0", "0"], // 默认总数
    };
  },
  mounted() {
    setTimeout(() => {
      this.toOrderNum(this.number); // 这里输入数字即可调用
    }, 500);
  },
  watch: {
    number: {
      handler(val) {
        this.toOrderNum(val);
      },
      deep: true,
    },
  },
  methods: {
    // 设置文字滚动
    setNumberTransform() {
      const numberItems = this.$refs.numberItem; // 拿到数字的ref，计算元素数量
      // eslint-disable-next-line no-restricted-globals
      const numberArr = this.orderNum.filter((item) => !isNaN(item));
      console.log(numberItems.length, numberArr);
      // 结合CSS 对数字字符进行滚动,显示数量
      for (let index = 0; index < numberItems.length; index += 1) {
        const elem = numberItems[index];
        elem.style.transform = `translateY(-${numberArr[index] * 10}%)`;
      }
    },
    // 处理总数字
    toOrderNum(num) {
      const numtext = num.toString();
      if (this.length) {
        if (numtext.length < this.length) {
          const numlist = `0${numtext}`; // 如未满固定数，添加"0"补位
          this.toOrderNum(numlist); // 递归添加"0"补位
        } else if (numtext.length === num.length) {
          this.orderNum = numtext.split(""); // 将其便变成数据，渲染至滚动数组
        }
      } else {
        this.orderNum = numtext.split("");
      }
      // 数字中加入逗号
      // const length = numtext.length / 3;
      // let count = '';
      // for (let i = 0; i < length; i += 1) {
      //   if (i === 0) {
      //     count += `${numtext.slice(i, i + 3)},`;
      //     console.log(count);
      //   } else if (i === length - 1) {
      //     count += numtext.slice(i * 3, i * 3 + 3);
      //     console.log(count);
      //   } else {
      //     count += `${numtext.slice(i * 3, i * 3 + 3)},`;
      //     console.log(count);
      //   }
      // }
      // this.orderNum = count.split('');
      this.setNumberTransform();
    },
  },
};
</script>
  <style scoped lang="scss">
.chartNum {
  display: inline-block;
}
/*总量滚动数字设置*/
.box-item {
  position: relative;
  height:80px;
  font-size: 20px;
  line-height: 41px;
  text-align: center;
  list-style: none;
  writing-mode: vertical-lr;
  text-orientation: upright;
}
/* 默认逗号设置 */
.mark-item {
  width: 28px;
  height:80px;
  position: relative;
  /* 背景图片 */
  list-style: none;
  margin-right: 1px;
  & > span {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 2px;
    left: 20%;
    font-size: 30px;
    writing-mode: vertical-rl;
    text-orientation: upright;
  }
}
/*滚动数字设置*/
.number-item {
  width: 32px;
  height: 65px;
  font-size: 60px;
  font-weight: 600;
  list-style: none;
  margin-right: 1px;
  & > span {
    position: relative;
    display: inline-block;
    margin-right: 10px;
    width: 100%;
    height: 100%;
    writing-mode: vertical-rl;
    text-orientation: upright;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    & > i {
      font-style: normal;
      position: absolute;
      top: 8px;
      // left: 50%;
      // transform: translate(-50%, 0);
      transition: transform 2.5s ease-in-out;
      letter-spacing: 10px;
    }
  }
}
.number-item:last-child {
  margin-right: 0;
}

@media screen and (max-width: 1024px){
  .chartNum {
    display: flex;
    justify-content: center;
}
/*总量滚动数字设置*/
.box-item {
  position: relative;
  height:8.0rem;
  font-size: 2.0rem;
  line-height: 4.1rem;
  text-align: center;
  list-style: none;
  writing-mode: vertical-lr;
  text-orientation: upright;
}
/* 默认逗号设置 */
.mark-item {
  width: 2.8rem;
  height:8.0rem;
  position: relative;
  /* 背景图片 */
  list-style: none;
  margin-right: .1rem;
  & > span {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: .2rem;
    left: 20%;
    font-size: 3.0rem;
    writing-mode: vertical-rl;
    text-orientation: upright;
  }
}
/*滚动数字设置*/
.number-item {
  width: 3.2rem;
  height: 6.5rem;
  font-size: 6.0rem;
  font-weight: 600;
  list-style: none;
  margin-right: .1rem;
  & > span {
    position: relative;
    display: inline-block;
    margin-right: 1.0rem;
    width: 100%;
    height: 100%;
    writing-mode: vertical-rl;
    text-orientation: upright;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    & > i {
      font-style: normal;
      position: absolute;
      top: .8rem;
      // left: 50%;
      // transform: translate(-50%, 0);
      transition: transform 2.5s ease-in-out;
      letter-spacing: 1.0rem;
    }
  }
}
.number-item:last-child {
  margin-right: 0;
}

}
</style>
  
  