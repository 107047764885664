import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/homePage/homePage.vue'),
    meta: {
      keepAlive: true, // 判断是否需要缓存当前组件
      isUseCache: true
    }
  },
  // 法律声明
  {
    path: '/legalDeclaration',
    name: 'legalDeclaration',
    component: () => import('@/views/pc/legalDeclaration/legalDeclaration.vue'),
    meta: {
      keepAlive: true, // 判断是否需要缓存当前组件
      isUseCache: false
    }
  },
  // 专业领域
  {
    path: '/areaExpertise',
    name: 'areaExpertise',
    component: () => import('@/views/pc/areaExpertise/areaExpertise.vue'),
    meta: {
      keepAlive: true, // 判断是否需要缓存当前组件
      isUseCache: false
    }
  },
  {
    path: '/areaExpertiseDetail',
    name: 'areaExpertiseDetail',
    component: () => import('@/views/pc/areaExpertise/areaExpertiseDetail.vue'),
    meta: {
      keepAlive: false, // 判断是否需要缓存当前组件
      isUseCache: false
    }
  },
  // 专业人员
  {
    path: '/professionalPersonnel',
    name: 'professionalPersonnel',
    component: () => import('@/views/pc/professionalPersonnel/professionalPersonnel.vue'),
    meta: {
      keepAlive: true, // 判断是否需要缓存当前组件
      isUseCache: false
    }
  },
  {
    path: '/professionalPersonnelDetail',
    name: 'professionalPersonnelDetail',
    component: () => import('@/views/pc/professionalPersonnel/professionalPersonnelDetail.vue'),
    meta: {
      keepAlive: false, // 判断是否需要缓存当前组件
      isUseCache: false
    }
  },
  // 京哲资讯
  {
    path: '/jingzheWelfare',
    name: 'jingzheWelfare',
    component: () => import('@/views/pc/jingzheDynamic/jingzheWelfare.vue'),
    meta: {
      keepAlive: true, // 判断是否需要缓存当前组件
      isUseCache: false
    }
  },
  {
    path: '/jingzhePerformance',
    name: 'jingzhePerformance',
    component: () => import('@/views/pc/jingzheDynamic/jingzhePerformance.vue'),
    meta: {
      keepAlive: true, // 判断是否需要缓存当前组件
      isUseCache: false
    }
  },
  {
    path: '/jingzheDynamic',
    name: 'jingzheDynamic',
    component: () => import('@/views/pc/jingzheDynamic/jingzheDynamic.vue'),
    meta: {
      keepAlive: true, // 判断是否需要缓存当前组件
      isUseCache: false
    }
  },
  {
    path: '/typicalCase',
    name: 'typicalCase',
    component: () => import('@/views/pc/jingzheDynamic/typicalCase.vue'),
    meta: {
      keepAlive: true, // 判断是否需要缓存当前组件
      isUseCache: false
    }
  },
  {
    path: '/jingzheDynamicDetail',
    name: 'jingzheDynamicDetail',
    component: () => import('@/views/pc/jingzheDynamic/jingzheDynamicDetail.vue'),
    meta: {
      keepAlive: false, // 判断是否需要缓存当前组件
      isUseCache: false
    }
  },
  //联系我们
  {
    path: '/onlineMessage',
    name: 'onlineMessage',
    component: () => import('@/views/pc/contactUs/onlineMessage.vue'),
    meta: {
      keepAlive: true, // 判断是否需要缓存当前组件
      isUseCache: false
    }
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: () => import('@/views/pc/contactUs/contactUs.vue'),
    meta: {
      keepAlive: true, // 判断是否需要缓存当前组件
      isUseCache: false
    }
  },
  {
    path: '/jobOpportunity',
    name: 'jobOpportunity',
    component: () => import('@/views/pc/contactUs/jobOpportunity.vue'),
    meta: {
      keepAlive: true, // 判断是否需要缓存当前组件
      isUseCache: false
    }
  },
  // 京哲介绍
  {
    path: '/jingzheHonor',
    name: 'jingzheHonor',
    component: () => import('@/views/pc/jingzheIntroduce/jingzheHonor.vue'),
    meta: {
      keepAlive: true, // 判断是否需要缓存当前组件
      isUseCache: false
    }
  },
  {
    path: '/enterJingzhe',
    name: 'enterJingzhe',
    component: () => import('@/views/pc/jingzheIntroduce/enterJingzhe.vue'),
    meta: {
      keepAlive: true, // 判断是否需要缓存当前组件
      isUseCache: false
    }
  },
  {
    path: '/socialResponsibility',
    name: 'socialResponsibility',
    component: () => import('@/views/pc/jingzheIntroduce/socialResponsibility.vue'),
    meta: {
      keepAlive: true, // 判断是否需要缓存当前组件
      isUseCache: false
    }
  },
  // 合作伙伴
  {
    path: '/cooperativePartner',
    name: 'cooperativePartner',
    component: () => import('@/views/pc/cooperativePartner/cooperativePartner.vue'),
    meta: {
      keepAlive: true, // 判断是否需要缓存当前组件
      isUseCache: false
    }
  },
  // 党建工作
  {
    path: '/partyBuildingWork',
    name: 'partyBuildingWork',
    component: () => import('@/views/pc/partyBuildingWork/partyBuildingWork.vue'),
    meta: {
      keepAlive: true, // 判断是否需要缓存当前组件
      isUseCache: false
    }
  },

  /***** 移动端 *****/
  // 法律声明
  {
    path: '/mobileLegalDeclaration',
    name: 'legalDeclaration',
    component: () => import('@/views/phone/legalDeclaration/legalDeclaration.vue'),
    meta: {
      keepAlive: true, // 判断是否需要缓存当前组件
      isUseCache: false
    }
  },
  // 党建工作
  {
    path: '/mobilePartyBuildingWork',
    name: 'mobilePartyBuildingWork',
    component: () => import('@/views/phone/partyBuildingWork/partyBuildingWork.vue'),
    meta: {
      keepAlive: true, // 判断是否需要缓存当前组件
      isUseCache: false
    }
  },
  // 合作伙伴
  {
    path: '/mobileCooperativePartner',
    name: 'mobileCooperativePartner',
    component: () => import('@/views/phone/cooperativePartner/cooperativePartner.vue'),
    meta: {
      keepAlive: true, // 判断是否需要缓存当前组件
      isUseCache: false
    }
  },
  // 京哲介绍
  {
    path: '/mobileJingzheHonor',
    name: 'mobileJingzheHonor',
    component: () => import('@/views/phone/jingzheIntroduce/jingzheHonor.vue'),
    meta: {
      keepAlive: true, // 判断是否需要缓存当前组件
      isUseCache: false
    }
  },
  {
    path: '/mobileEnterJingzhe',
    name: 'mobileEnterJingzhe',
    component: () => import('@/views/phone/jingzheIntroduce/enterJingzhe.vue'),
    meta: {
      keepAlive: true, // 判断是否需要缓存当前组件
      isUseCache: false
    }
  },
  //联系我们
  {
    path: '/mobileOnlineMessage',
    name: 'mobileOnlineMessage',
    component: () => import('@/views/phone/contactUs/onlineMessage.vue'),
    meta: {
      keepAlive: true, // 判断是否需要缓存当前组件
      isUseCache: false
    }
  },
  {
    path: '/mobileContactUs',
    name: 'mobileContactUs',
    component: () => import('@/views/phone/contactUs/contactUs.vue'),
    meta: {
      keepAlive: true, // 判断是否需要缓存当前组件
      isUseCache: false
    }
  },
  {
    path: '/mobileJobOpportunity',
    name: 'mobileJobOpportunity',
    component: () => import('@/views/phone/contactUs/jobOpportunity.vue'),
    meta: {
      keepAlive: true, // 判断是否需要缓存当前组件
      isUseCache: false
    }
  },
  // 京哲咨询
  {
    path: '/mobileJingzheWelfare',
    name: 'mobileJingzheWelfare',
    component: () => import('@/views/phone/jingzheDynamic/jingzheWelfare.vue'),
    meta: {
      keepAlive: true, // 判断是否需要缓存当前组件
      isUseCache: false
    }
  },
  {
    path: '/mobileJingzhePerformance',
    name: 'mobileJingzhePerformance',
    component: () => import('@/views/phone/jingzheDynamic/jingzhePerformance.vue'),
    meta: {
      keepAlive: true, // 判断是否需要缓存当前组件
      isUseCache: false
    }
  },
  {
    path: '/mobileJingzheDynamic',
    name: 'mobileJingzheDynamic',
    component: () => import('@/views/phone/jingzheDynamic/jingzheDynamic.vue'),
    meta: {
      keepAlive: true, // 判断是否需要缓存当前组件
      isUseCache: false
    }
  },
  {
    path: '/mobileJingzheDynamicDetail',
    name: 'mobileJingzheDynamicDetail',
    component: () => import('@/views/phone/jingzheDynamic/jingzheDynamicDetail.vue'),
    meta: {
      keepAlive: false, // 判断是否需要缓存当前组件
      isUseCache: false
    }
  },
  {
    path: '/mobileTypicalCase',
    name: 'mobileTypicalCase',
    component: () => import('@/views/phone/jingzheDynamic/typicalCase.vue'),
    meta: {
      keepAlive: true, // 判断是否需要缓存当前组件
      isUseCache: false
    }
  },
  // 专业领域
  {
    path: '/mobileAreaExpertise',
    name: 'mobileAreaExpertise',
    component: () => import('@/views/phone/areaExpertise/areaExpertise.vue'),
    meta: {
      keepAlive: true, // 判断是否需要缓存当前组件
      isUseCache: false
    }
  },
  {
    path: '/mobileAreaExpertiseDetail',
    name: 'mobileAreaExpertise',
    component: () => import('@/views/phone/areaExpertise/areaExpertiseDetail.vue'),
    meta: {
      keepAlive: false, // 判断是否需要缓存当前组件
      isUseCache: false
    }
  },
  // 专业人员
  {
    path: '/mobileProfessionalPersonnel',
    name: 'mobileProfessionalPersonnel',
    component: () => import('@/views/phone/professionalPersonnel/professionalPersonnel.vue'),
    meta: {
      keepAlive: true, // 判断是否需要缓存当前组件
      isUseCache: false
    }
  },
  {
    path: '/mobileProfessionalPersonnelDetail',
    name: 'mobileProfessionalPersonnelDetail',
    component: () => import('@/views/phone/professionalPersonnel/professionalPersonnelDetail.vue'),
    meta: {
      keepAlive: false, // 判断是否需要缓存当前组件
      isUseCache: false
    }
  },

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

//先把VueRouter原型对象的push保存一份
const originPush = VueRouter.prototype.push
//重写push方法 
VueRouter.prototype.push = function (location, resolve, reject) {
  //调用保存的push方法
  //但是保存的push方法是挂载在window的方法 所以要通过call修改this的指向

  if (resolve && reject) {
    originPush.call(this, location, resolve, reject);
  } else {
    originPush.call(this, location, () => { }, () => { });
  }
}


export default router
