<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive && pageShow" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive && pageShow" />
    <floatService v-if="floatShow" :class="$route.path == '/' ? 'float' : ''"></floatService>
  </div>
</template>

<script>
export default {
  data() {
    return {
      floatShow: false,
      pageShow: false,
    };
  },
  created() {
    this.$axios.get(process.env.VUE_APP_API + "/api/v1/index").then((res) => {
      if (res.status === 200) {
        document.title = res.data.title
        var link = document.querySelector("link[rel*='icon']") || document.createElement('link')
        link.type = 'image/x-icon'
        link.rel = 'shortcut icon'
        link.href = res.data.icon
        document.getElementsByTagName('head')[0].appendChild(link)
        this.$tools.localStorageFn.set("PAGE_DATE", res.data);
        if (!this.$tools.localStorageFn.get("FIELD_LIST")) {
          this.pageShow = false
          this.$axios
            .get(process.env.VUE_APP_API + "/api/v1/fields", { params: { per_page: 999 } })
            .then((res) => {
              console.log("业务领域", res);
              if (res.status === 200) {
                this.options = res.data;
                this.$tools.localStorageFn.set("FIELD_LIST", res.data.data);
                this.$router.push({ query: {} })
              } else {
                this.$message.error(res.statusText);
              }
            }).finally(() => {
              this.pageShow = true
            });
        } else {
          this.pageShow = true
        }
      } else {
        this.$message.error(res.statusText);
      }
    });
    console.log(document.body.clientWidth)
    if (document.body.clientWidth > 1024) {
      this.floatShow = true
    }
  },
  mounted() {
    console.log();
    var _this = this;

    // let headHTML = document.getElementsByTagName("head")[0].innerHTML;
    // headHTML +=
    //   '<link rel="icon" href="' +
    //   this.$tools.localStorageFn.get("PAGE_DATE").icon +
    //   '">';
    // document.getElementsByTagName("head")[0].innerHTML = headHTML;
    // document.title =
    //   this.$tools.localStorageFn.get("PAGE_DATE").site_description;

    window.onresize = function () {
      // 定义窗口大小变更通知事件

      _this.screenHeight = document.documentElement.clientHeight; //窗口宽度
      window.location.reload();
    };
  },
};
</script>

<style lang="scss">
html,
body,
#app {
  height: 100%;
  position: relative;
}

div {
  box-sizing: border-box;
}

.pointer {
  cursor: pointer;
}

.m_left {
  .ml_bottom {
    .t_img {
      overflow: hidden;

      img {
        transition: 0.6s all;
      }
    }

    .t_img:hover {
      img {
        transform: scale(1.2, 1.2);
      }
    }
  }
}

.labeltitle {
  transition: 0.6s;
}

.labeltitle:hover {
  color: #00305f;
  font-size: 4.8rem;
}

.float {
  right: 10.4rem !important;
}

@media screen and (min-width: 1920px) {
  .labeltitle {
    transition: 0.6s;
  }

  .labeltitle:hover {
    color: #00305f;
    font-size: 48px;
  }

  .float {
    right: 104px !important;
  }
}
</style>
