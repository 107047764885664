import router from '@/router'
export default {
    // localStorage，设置、获取、删除
    localStorageFn: {
        set: function (key, value) {
            localStorage.setItem(key, JSON.stringify(value));
        },
        get: function (key, value) {
            return JSON.parse(localStorage.getItem(key, value));
        },
        remove: function (key, value) {
            localStorage.removeItem(key);
        }
    },

    isElementNotInViewport: function (el) {
        if (el) {
            let rect = el.getBoundingClientRect();
            return (
                rect.top >=
                (window.innerHeight || document.documentElement.clientHeight) ||
                rect.bottom <= 0
            );
        }
    },

    blankPush: function (path) {
        console.log(router)
        let text = router.resolve({
            path: path
        });

        // 打开一个新的页面     
        window.open(text.href, '_blank')
    }
}