
import router from './router'

router.beforeEach((to, from, next) => {
    console.log('to', to)
    console.log('from', from)
    // 让页面回到顶部
    document.documentElement.scrollTop = 0;
    next();
});
