var timer = null;
var baseSize = 10;
//解决：屏幕横屏竖屏的时候没办法及时更新页面，需要刷新才可以--适用移动端
/*function setRem(){
  clearTimeout(timer);
  // 延迟屏幕横竖转换
  timer = setTimeout(function () {
    document.documentElement.style.fontSize=document.documentElement.offsetWidth/90+"px"; //同上
  }, 200);
}*/
//pc端
function setRem() {
    if (document.documentElement.clientWidth <= 1024) {
        document.documentElement.style.fontSize = '2.666666666vw'
    } else {
        // 当前页面宽度相对于 750 宽的缩放比例，可根据自己需要修改。
        const scale = document.documentElement.clientWidth / 1920//1726
        // 设置页面根节点字体大小
        document.documentElement.style.fontSize = (baseSize * Math.min(scale, 2)) + 'px'
    }
}
setRem();
if ('addEventListener' in document) {
    document.addEventListener('orientationchange', function () {
        setRem();
    }, false);
    window.addEventListener('resize', function () {
        setRem();
    }, false);
}