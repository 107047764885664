<template>
  <div class="myHeaderBox" id="myHeaderBox">
    <div class="myNav">
      <div class="mN_right">
        <div class="mN_icons">
          <div class="mN_icon icon_search02 pointer" @click="searchShow = !searchShow"></div>
          <div class="mN_icon icon_menu pointer" @mouseenter="navShow = !navShow">
            <div class="t_menuitem"></div>
            <div class="t_menuitem t_menuitem2"></div>
            <div class="t_menuitem"></div>
          </div>
        </div>
      </div>
      <div class="myLogo" @click="() => {
        jumpRouter('/');
      }
        ">
        <img src="@/assets/images/logo02.png" alt="" />
      </div>
      <div class="mN_right">
        <div class="mN_lage pointer"></div>
        <div class="mN_lag pointer">
          <div class="text">CN</div>
        </div>
      </div>
    </div>
    <!-- <div class="occupySpace"></div> -->
    <div class="mh_img" v-if="show" :style="'background: url(' + imgBg + ') no-repeat center center'">
      <!-- <img src="@/assets/images/myNavBg.png" alt="" /> -->
      <!-- <video class="video-background" preload="auto" loop playsinline autoplay :src="videoSrc" tabindex="-1"
        muted="muted"></video> -->
      <div class="mh_title">
        <div class="label">{{ title }}</div>
        <!-- <div class="line"></div> -->
      </div>
    </div>

    <!-- 导航弹窗 -->
    <my-menu :class="navShow ? 'on' : ''" @closeNav="closeNav"></my-menu>

    <!-- 搜索弹窗 -->
    <van-popup v-model="searchShow" position="top" :style="{ height: '60%' }" :z-index="1500">
      <div class="searchArea">
        <div class="s_searchIcon pointer" @click="() => {
          searchShow = false;
        }
          ">
          <div class="s_close"></div>
        </div>
        <div class="s_nav">
          <div class="s_logo"></div>
        </div>
        <div class="s_title">探索京哲</div>
        <div class="s_mid">
          <div class="s_search">
            <div class="s_input">
              <input type="text" placeholder="搜索" name="" id="" v-model="searchText" v-loading="loading" />
            </div>
            <div class="s_goSearch" @click="search(1)">
              <div class="s_rightArrow pointer"></div>
            </div>
          </div>
          <div class="s_label">京哲智慧，开启未来</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: ["imgShow", "video"],
  data() {
    return {
      navShow: false,
      value: "",
      currentTime: 0,
      title: "",
      show: true,
      searchShow: false,

      imgBg: '',
      videoSrc: "",

      searchText: '',
      loading: false,
    };
  },
  created() {
    if (this.imgShow == false) {
      this.show = this.imgShow;
    } else {
      this.show = true;
    }
  },

  methods: {
    // 关闭导航弹窗
    closeNav() {
      this.navShow = false;
    },
    jumpRouter(route) {
      if (this.$route.path == route) {
        this.$router.go(0);
      } else {
        this.$router.push(route);
      }
    },

    initCurrent() {
      let that = this;
      var test1 = setInterval(function () {
        // console.log(22222, that.currentTime);
        that.currentTime += 200;
        if (that.currentTime > 28 * 200) {
          // console.log("大于");
          clearInterval(test1);
        }
      }, 200);
    },
    changeActive(event) {
      // console.log("元素", event);
      // console.log("1111", document.getElementsByClassName("n_menuItem_detail"));
      let list = document.getElementsByClassName("n_menuItem_detail");
      this.$refs.select.blur();
      for (let i = 0; i < list.length; i++) {
        // console.log(list[i]);
        list[i].style.width = "0";
      }
      let list02 = document.getElementsByClassName("n_menuItem_icon");
      for (let i = 0; i < list02.length; i++) {
        // console.log(list02[i]);
        list02[i].classList.remove("n_menuItem_minus");
      }
      if (event.target.childNodes[1]) {
        event.target.childNodes[1].classList.add("n_menuItem_minus");
      }
      if (event.target.parentNode.childNodes[1]) {
        event.target.parentNode.childNodes[1].style.width =
          "calc(100% - 162px)";
      }
    },

    search(s) {
      if (this.searchText == '') {
        this.$message.warning('请输入搜索内容!')
        return
      } else {
        this.loading = true
        this.$axios.get(process.env.VUE_APP_API + '/api/v1/articles', {
          params: {
            filter: { title: this.searchText }
          }
        }).then((res) => {
          console.log('搜索结果', res)
          if (res.status == 200) {
            if (res.data.data.length == 0) {
              this.$message.warning('未查询任何数据!')
              return
            }
            this.searchShow = false
            if (s == 1) {
              this.$router.push('/jingzheDynamic?q=' + this.searchText)
            } else { this.$router.push('/mobileJingzheDynamic?q=' + this.searchText) }
          } else {
            this.$message.error(res.message);
          }
        }).finally(() => {
          this.loading = false;
        });

      }
    }
  },
};
</script>

<style lang="scss" scoped>
.myHeaderBox {
  position: relative;

  .myNav {
    position: absolute;
    top: 6rem;
    left: 0;
    padding: 0 8rem 0 8rem;
    width: 100%;
    // height: 11.0rem;
    // background: #ffffff;
    background: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 205;

    .myLogo {
      width: 20.5rem;
      height: 4.5rem;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .mN_right {
      display: flex;
      align-items: center;

      // column-gap: 5.2rem;
      .mN_lage {
        margin-right: 5.2rem;
        font-size: 3rem;
        font-family: Microsoft YaHei UI;
        font-weight: 400;
        color: #fff;
      }

      .mN_lag {
        background: url(~@/assets/images/icon_language.png) no-repeat center center;
        background-size: 100%;
        width: 2.8rem;
        height: 2.8rem;
        // margin-right: 5.2rem;
        font-size: 2rem;
        font-family: Microsoft YaHei UI;
        font-weight: 400;
        color: #fff;
        position: relative;

        .text {
          position: absolute;
          right: 0;
          bottom: 0;
          transform: translate(50%, 50%);
          font-size: 1.2rem;
          font-weight: 600;
        }
      }

      .mN_icons {
        display: flex;

        // column-gap: 2.5rem;
        .mN_icon {
          margin-right: 2.5rem;
          width: 2.8rem;
          height: 2.8rem;
        }

        .icon_search02 {
          background: url(~@/assets/images/icon_search01.png) no-repeat center center;
          background-size: 100% 100%;
        }

        .icon_menu {
          width: 2.6rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .t_menuitem {
            width: 100%;
            height: 0.3rem;
            background: #fff;
          }
        }

        .icon_menu:hover .t_menuitem2 {
          transform: translateX(-50%);
        }
      }
    }
  }

  .mh_img {
    width: 100%;
    height: 45rem;

    // background: url(~@/assets/images/myNavBg.png) no-repeat center center;
    background-size: cover;
    position: relative;

    // transform: scale(1.1, 1.1);
    // -webkit-transform: scale(1.1, 1.1);
    transition: 0.6s all;

    // transition: 1s 0.6s;
    video {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }

    img {
      width: 100%;
      visibility: hidden;
    }

    .mh_title {
      z-index: 200;
      animation: tilt-in-left-_8 1s ease 0s 1 normal none;
      transition: 0.6s all;
      position: absolute;
      top: 19.9rem;
      left: 25rem;
      // width: 63rem;
      height: 13rem;
      // background: #00305f;
      opacity: 0.8;
      display: flex;
      flex-direction: column;
      align-items: left;
      row-gap: 2.3rem;
      justify-content: center;

      .label {
        font-size: 6.4rem;
        font-family: JingZhe_XiaoWei;
        font-weight: 500;
        color: #ffffff;
        line-height: 6.8rem;
      }

      .line {
        width: 12rem;
        height: 0.3rem;
        background: #ffffff;
      }
    }
  }

  .mh_img:hover {

    // transform: scale(1.1, 1.1);
    .mh_title {
      transform: scale(1.2);
    }
  }

  .occupySpace {
    height: 11rem;
  }

  ::v-deep .van-overlay {
    z-index: 1498 !important;
  }

  .searchArea {
    height: 100%;
    background: #f8f8f8;
    padding: 4.8rem 6.8rem 0;
    position: relative;

    .s_searchIcon {
      position: absolute;
      right: 6.8rem;
      top: 4.8rem;
      width: 3.6rem;
      height: 3.6rem;
      background: #0e3a68;
      border-radius: 1.8rem;

      .s_close {
        margin: auto;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        width: 1.4rem;
        height: 1.4rem;
        background: url(~@/assets/images/icon_close.png) no-repeat center center;
        background-size: cover;
      }
    }

    .s_nav {
      .s_logo {
        margin: 0 auto;
        width: 20.5rem;
        height: 4.5rem;
        background: url(~@/assets/images/logo03.png) no-repeat center center;
        background-size: cover;
      }
    }

    .s_title {
      margin-top: 6.9rem;
      font-size: 2.1rem;
      font-family: Microsoft YaHei UI;
      font-weight: 400;
      color: #212121;
      line-height: 2.5rem;
      text-align: center;
      margin-bottom: 10.5rem;
    }

    .s_mid {
      width: 57rem;
      margin: 0 auto;

      .s_search {
        display: flex;
        justify-content: center;

        .s_input {
          input {
            background: transparent;
            width: 52rem;
            line-height: 3.6rem;
            font-size: 1.8rem;
            border: 0;
            border-bottom: 0.1rem solid rgba(14, 58, 104, 1);
          }
        }

        .s_goSearch {
          width: 3.2rem;
          height: 3.2rem;
          background: #0e3a68;
          border-radius: 1.6rem 1.6rem 1.6rem 1.6rem;
          margin-left: 0.8rem;

          .s_rightArrow {
            width: 1.6rem;
            height: 1.2rem;
            margin: 1rem auto;
            background: url(~@/assets/images/icon_rightArrow02.png) no-repeat center center;
            background-size: 100% 100%;
          }
        }
      }

      .s_label {
        margin-top: 1.8rem;
        padding-left: 0.2rem;
        font-size: 1.6rem;
        font-family: Microsoft YaHei UI;
        font-weight: 400;
        color: #0e3a68;
        line-height: 1.9rem;
      }
    }
  }

  /*屏幕宽度大于1920px,改变布局和样式*/
  @media screen and (min-width: 1920px) {
    .myNav {
      position: absolute;
      top: 60px;
      left: 0;
      padding: 0 80px 0 80px;
      width: 100%;
      // height: 110px;
      // background: #ffffff;
      background: transparent;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 205;

      .myLogo {
        width: 205px;
        height: 45px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .mN_right {
        display: flex;

        // column-gap: 52px;
        .mN_lage {
          margin-right: 52px;
          font-size: 30px;
          font-family: Microsoft YaHei UI;
          font-weight: 400;
          color: #fff;
        }

        .mN_lag {
          // margin-right: 52px;
          width: 28px;
          height: 28px;
          font-size: 20px;
          font-family: Microsoft YaHei UI;
          font-weight: 400;
          color: #fff;

          .text {
            font-size: 12px;
          }
        }

        .mN_icons {
          display: flex;

          // column-gap: 25px;
          .mN_icon {
            margin-right: 25px;
            width: 28px;
            height: 28px;
          }

          .icon_search02 {
            background: url(~@/assets/images/icon_search01.png) no-repeat center center;
            background-size: 100% 100%;
          }

          .icon_menu {
            width: 26px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .t_menuitem {
              width: 100%;
              height: 3px;
              background: #fff;
            }
          }

          .icon_menu:hover .t_menuitem2 {
            transform: translateX(-50%);
          }
        }
      }
    }

    .mh_img {
      width: 100%;
      height: 450px;
      background-size: cover;
      position: relative;

      // transform: scale(1.1, 1.1);
      // -webkit-transform: scale(1.1, 1.1);
      transition: 0.6s all;

      // transition: 1s 0.6s;
      img {
        width: 100%;
        visibility: hidden;
      }

      .mh_title {
        top: 199px;
        left: 250px;
        height: 130px;
        row-gap: 23px;

        .label {
          font-size: 64px;
          line-height: 68px;
        }

        .line {
          width: 120px;
          height: 3px;
        }
      }
    }

    .occupySpace {
      height: 110px;
    }

    ::v-deep .van-overlay {
      z-index: 1498 !important;
    }

    // 动画
    .n_area {
      .n_menuItem_detailItem n_bottom48 {
        opacity: 0;
      }

      .n_menuItem_label {
        opacity: 0;
      }

      .n_bottom48 {
        opacity: 1;
        animation-name: fade-in-bottom48;
        animation-duration: 0.5s;
        animation-timing-function: ease-in;
        animation-delay: 0s;
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-fill-mode: none;
      }

      .n_right108 {
        animation-name: fade-in-right108;
        animation-duration: 1.5s;
        animation-timing-function: ease-in;
        animation-delay: 0s;
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-fill-mode: none;
      }

      .n_left48 {
        opacity: 1;
        animation-name: fade-in-left48;
        animation-duration: 1s;
        animation-timing-function: ease-in;
        animation-delay: 0s;
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-fill-mode: none;
      }
    }

    .searchArea {
      height: 100%;
      background: #f8f8f8;
      padding: 48px 68px 0;
      position: relative;

      .s_searchIcon {
        position: absolute;
        right: 68px;
        top: 48px;
        width: 36px;
        height: 36px;
        background: #0e3a68;
        border-radius: 18px;

        .s_close {
          margin: auto;
          position: relative;
          top: 50%;
          transform: translateY(-50%);
          width: 14px;
          height: 14px;
          background: url(~@/assets/images/icon_close.png) no-repeat center center;
          background-size: cover;
        }
      }

      .s_nav {
        .s_logo {
          margin: 0 auto;
          width: 205px;
          height: 45px;
          background: url(~@/assets/images/logo03.png) no-repeat center center;
          background-size: cover;
        }
      }

      .s_title {
        margin-top: 69px;
        font-size: 21px;
        font-family: Microsoft YaHei UI;
        font-weight: 400;
        color: #212121;
        line-height: 25px;
        text-align: center;
        margin-bottom: 105px;
      }

      .s_mid {
        width: 570px;
        margin: 0 auto;

        .s_search {
          display: flex;
          justify-content: center;

          .s_input {
            input {
              background: transparent;
              width: 520px;
              line-height: 36px;
              font-size: 18px;
              border: 0;
              border-bottom: 1px solid rgba(14, 58, 104, 1);
            }
          }

          .s_goSearch {
            width: 32px;
            height: 32px;
            background: #0e3a68;
            border-radius: 16px 16px 16px 16px;
            margin-left: 8px;

            .s_rightArrow {
              width: 16px;
              height: 12px;
              margin: 10px auto;
              background: url(~@/assets/images/icon_rightArrow02.png) no-repeat center center;
              background-size: 100% 100%;
            }
          }
        }

        .s_label {
          margin-top: 18px;
          padding-left: 2px;
          font-size: 16px;
          font-family: Microsoft YaHei UI;
          font-weight: 400;
          color: #0e3a68;
          line-height: 19px;
        }
      }
    }
  }
}
</style>